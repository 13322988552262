<script setup lang="ts">
import type { PopperOptions } from '#ui/types'
import { defu } from 'defu'

interface Props {
  text?: string
  size?: keyof typeof sizes
  popper?: PopperOptions
}

const props = defineProps<Props>()

const ui = defineUi('popover', {
  arrow: {
    base: `       z-10 before:w-4 before:h-4 before:relative before:shadow-none`,
    ring: `       before:ring-0`,
    background: ` before:bg-forgd-primary-900`,
    shadow: `     before:shadow-none`,
    placement: `  group-data-[popper-placement*="right"]:-left-2
                  group-data-[popper-placement*="left"]:-right-2
                  group-data-[popper-placement*="top"]:-bottom-1
                  group-data-[popper-placement*="bottom"]:-top-1`,
  },
})

const popper = computed(() => defu(
  props.popper,
  {
    placement: 'top',
    strategy: 'fixed', // ensures top shows on top (otherwise `relative` ancestor elements make it show below)
    arrow: false, // arrows are buggy on hover (they jump down on transition-end) so disabled for now
  },
) as any as PopperOptions)
</script>

<script lang="ts">
export const sizes = {
  xs: 'max-w-[80px]',
  sm: 'max-w-[160px]',
  md: 'max-w-[240px]',
  lg: 'max-w-[440px]',
  xl: 'max-w-[640px]',
}
</script>

<template>
  <UPopover
    data-ui="UiTooltip"
    mode="hover"
    :ui="ui"
    :popper="popper"
    :class="$slots.default ? 'inline-block' : 'inline-flex'"
  >
    <slot v-if="$slots.default" />
    <UiIcon v-else name="i-heroicons-question-mark-circle" color="primary" size="lg" />
    <template #panel>
      <div
        class="p-3 h-auto
               text-xs text-left
               font-display font-normal
               whitespace-normal
               leading-normal
               space-y-2
               bg-forgd-accent-900 text-white
               pointer-events-none
              "
      >
        <div :class="`w-max ${sizes[size || 'md']}`">
          <slot name="text">
            {{ $slots.text || text || 'Sorry, missing tooltip!' }}
          </slot>
        </div>
      </div>
    </template>
  </UPopover>
</template>

<style lang="scss">
div[data-ui="UiTooltip"] {
  p, ul {
    &:not(:last-child) {
      @apply mb-3;
    }
  }

  ul {
    @apply pl-6 pr-2 list-disc;
  }

  li {
    @apply mb-1 list-disc;
  }

  code {
    @apply px-1 py-0.5 rounded-md bg-slate-700;
  }
}
</style>
